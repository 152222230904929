import React from 'react';
import { Container, Typography } from '@material-ui/core';

function Signup (props) {
  return (
    <Container>
      <Typography
        variant="h2"
      >
        Signup
      </Typography>
    </Container>
  )
}

export default Signup;