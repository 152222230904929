import React from 'react';
import { 
  Typography, 
  Container 
} from '@material-ui/core';

function Signin (props) {
  return (
    <Container>
      <Typography
        variant="h2"
      >
        Sign-in
      </Typography>
    </Container>
  )
}

export default Signin;