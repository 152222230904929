/*global firebaseui, firebase*/

import React from 'react';
import {
  Redirect,
} from 'react-router-dom';


class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    this.ui.start('#firebaseui-auth-container', this.getUiConfig());
    firebase.auth().onAuthStateChanged(this.handleAuthStateChanged);
    this.state = {
      signedIn: false,
    }
  }

  /**
   * @return {!Object} The FirebaseUI config.
   */
  getUiConfig = () => {
    return {
      'callbacks': {
        // Called when the user has been successfully signed in.
        'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
          if (authResult.user) {
            this.handleSignedInUser();
          }
          if (authResult.additionalUserInfo) {
            if (authResult.additionalUserInfo.isNewUser) {
              console.log("new user");
            }
          }
        }
      },
      'signInFlow': 'popup',
      'signInOptions': [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      'tosUrl': '<your-tos-url>',
      //Privacy Policy url/callback.
      'privacePolicyUrl': function() {
        window.location.assign('<your-privacy-policy-url>');
      },
    };
  }

  handleAuthStateChanged = (user) => {
    user ? this.handleSignedInUser() : this.handleSignedOutUser();
  }

  handleSignedInUser = () => {
    this.state.signedIn = true;
  }

  handleSignedOutUser = () => {
    this.state.signedIn = false;
    this.ui.start('#firebaseui-container', this.getUiConfig());
  }

  render() {
    return (
      <Redirect to='/signin'/>
    );
  }
};

export default Auth;