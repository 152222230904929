import React from 'react';
import {
  Button,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";

import '../css/Home.css';

function Home() {
  return (
    <div className="Home">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
          >
            Tutor App
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
          >
            Knowledge share for students
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input/>
        </Grid>
        <Grid item xs>
          <Button
            color="inherit"
            className="Home-login-button"
            disableTouchRipple
          >
            <Typography
              variant='h6'
              className='Home-login-button-text'
            >
              Log in
            </Typography>
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            color="inherit"
            className="Home-signup-button"
            disableTouchRipple
          >
            <Typography
              variant='h6'
              className='Home-signup-button-text'
            >
              Sign Up
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
