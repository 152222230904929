import React from 'react';
import {
  Route,
  Link,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import {
  CssBaseline
} from '@material-ui/core';

import Home from './Home';
import Signin from './Signin';
import Signup from './Signup';
import Auth from './Auth';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: props.signedIn,
    }
  }
  redirectHomeIfSignedInOrRedirectToSignin() {
    if(this.state.signedIn){
      return (
        <Redirect to='/'/>
      )
    }
    return (
      <Redirect to='/signin'/>
    )
  }
  render () {
    return (
      <>
        <CssBaseline/>
        <BrowserRouter>
          <Auth/>
          <Route exact path='/' component={Home}/>
          <Route path='/signin' component={Signin}/>
          <Route path='/signup' component={Signup}/>
        </BrowserRouter>
      </>
    )
  }
}

export default Main;